import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import { bridge } from '@bridge'

import IconCheck from './assets/images/icon_check.svg'
import IconCheckCircle from './assets/images/icon_check_circle.svg'
import IconCheckCircleDisabled from './assets/images/icon_check_circle_disabled.svg'
import IconCheckDisabled from './assets/images/icon_check_disabled.svg'
import IconChevron from './assets/images/icon_chevron_right.svg'

const TERMS_LIST = [
  {
    id: 'term001',
    title: '(필수) 개인정보 수집 및 이용 동의',
    url: `${process.env.REACT_APP_AUTH_URL}/terms/1_20220222.html`,
    checked: false,
  },
  {
    id: 'term002',
    title: '(필수) 본인확인서비스 이용약관',
    url: 'https://safe.ok-name.co.kr/eterms/agreement002.jsp',
    checked: false,
  },
  {
    id: 'term003',
    title: '(필수) 통신사 이용 약관',
    url: 'https://safe.ok-name.co.kr/eterms/agreement004.jsp',
    checked: false,
  },
  {
    id: 'term004',
    title: '(필수) 고유식별정보처리 동의',
    url: 'https://safe.ok-name.co.kr/eterms/agreement003.jsp',
    checked: false,
  },
  {
    id: 'term005',
    title: '(필수) 개인정보 제3자 동의',
    url: 'https://safe.ok-name.co.kr/eterms/agreement005.jsp',
    checked: false,
  },
  {
    id: 'term006',
    title: '(필수) 개인정보 수집/이용/취급 위탁동의',
    url: 'https://safe.ok-name.co.kr/eterms/agreement001.jsp',
    checked: false,
  },
]

function App() {
  const [termsList, setTermsList] = useState(TERMS_LIST)
  const [allChecked, setAllChecked] = useState(false)

  const handleAllChange = (e: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setAllChecked(e.currentTarget.checked)
    setTermsList(termsList.map((item) => ({ ...item, checked: !!e.currentTarget.checked })))
  }

  const handleCheckboxChange = (index: number) => {
    setTermsList(termsList.map((item, i) => (i === index ? { ...item, checked: !item.checked } : item)))
  }

  const handleConfirm = () => {
    bridge.stream.emit('KARROTAUTH_TERMS_LOGEVENT_FROM_WEBVIEW', {
      name: 'click_user_verification',
      params: { screen_name: 'terms', btn_name: 'next' },
    })
    bridge.stream.emit('KARROTAUTH_TERMS_PARAMS_FROM_WEBVIEW', {})
    bridge.plugin.close()
  }

  const handleOpenTermPage = (url: string, title: string) => {
    bridge.router.push({
      remote: url,
      navbar: true,
      present: true,
      navbarTitle: title,
      scrollable: true,
    })
  }

  useEffect(() => {
    setAllChecked(termsList.every((item) => item.checked))
  }, [termsList])

  return (
    <Wrap>
      <AllAgreeTermsSection>
        <input id="allagree" type="checkbox" checked={allChecked} onChange={handleAllChange} />
        <label htmlFor="allagree">약관 모두 동의</label>
      </AllAgreeTermsSection>
      <TermsSection>
        {termsList.map(({ id, title, url, checked }, i) => {
          return (
            <li key={id}>
              <input type="checkbox" id={id} checked={checked} onChange={() => handleCheckboxChange(i)} />
              <label htmlFor={id}>{title}</label>
              <RightArrow onClick={() => handleOpenTermPage(url, title)} />
            </li>
          )
        })}
      </TermsSection>
      <Button onClick={handleConfirm} disabled={!allChecked}>
        확인
      </Button>
    </Wrap>
  )
}

export default App

const Wrap = styled.main`
  padding: 32px 16px 16px 16px;
`

const AllAgreeTermsSection = styled.section`
  border-bottom: 1px solid #dcdee3;
  padding-bottom: 17px;

  input {
    display: none;

    & + label:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IconCheckCircleDisabled}) no-repeat center center;
      vertical-align: middle;
      margin-right: 12px;
    }

    &:checked + label:before {
      background: url(${IconCheckCircle}) no-repeat center center;
    }

    + label {
      font-weight: bold;
      font-size: 16px;
      color: #212124;
    }
  }
`

const TermsSection = styled.ul`
  li {
    margin-top: 16px;
    display: flex;
    align-items: center;

    input {
      display: none;

      & + label:before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(${IconCheckDisabled}) no-repeat center center;
        vertical-align: middle;
        margin-right: 12px;
      }

      &:checked + label:before {
        background: url(${IconCheck}) no-repeat center center;
      }

      + label {
        font-size: 16px;
        letter-spacing: -0.02em;

        @media (max-width: 340px) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 40px);
        }
      }
    }
  }
`

const Button = styled.button`
  background: #ff7e36;
  border-radius: 6px;
  width: 100%;
  padding: 12.5px 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  margin-top: 16px;

  &:disabled {
    background: #dcdee3;
  }
`

const RightArrow = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(${IconChevron});
  margin-left: auto;
`
